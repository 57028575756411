@import '../../../../styles/colors.scss';
.main-info {
    h1 {
        color: $black;
        font-size: 18px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 3px;
    }
    .code {
        font-weight: 600;
        letter-spacing: 4px;
        font-size: 20px;
    }
    p {
        color: $black;
        font-size: 12px;
    }
    h3 {
        font-size: 18px;
        font-weight: 500;
    }
    .social-logo {
        display: flex;
        justify-content: start;
        gap: 5px;
    }
}