@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import './colors.scss';

@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100vh;
}

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

.row{
  display: flex;
  width: 100%;
  gap:16px;
}

.col{
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $scrollBar;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $scrollBarBack;
}
