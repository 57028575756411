@import '../../styles/colors.scss';
@import '../../styles/responsiveSize.scss';

.main-layout {
  min-height: 100vh;
  width: 100%;
  background-color: $secondaryGrayDark;
  display: flex;
  .content-layout {
    margin-left: 266px;
    width:calc( 100% - 266px);
    transition: margin-left 0.2s ease-in;
    @include media('mobile'){
    margin-left: 0;
    }
    &.closed {
    width:calc( 100% - 70px);

      margin-left: 70px;
    }
    .main-body{
      width: 100%;
      padding: 24px;
      /* background-color: red; */
      &.strech{
      padding:24px 124px;
      @include media('desktop'){
          padding: 24px 32px;
        }
        @include media('fullscreen'){
          padding: 24px 116px;
        }
      }
    }
  }
}
