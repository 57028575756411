@import '../../../../styles/colors.scss';
@import '../../../../styles/responsiveSize';

.navbar {
  padding: 20px 20px 20px 5px;
  
  .content {
    border-radius: 25px;
     background-color: $bkgNav;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
    p{
      margin: 0;
      margin-top: 5px;
    }
    h3 {
      margin: 0;
    }
  .actions {
    display: flex;
    gap: 24px;
  }
  }
 
}
