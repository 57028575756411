$main: #f36196;
$mainDark: #e2427c;
$mainLight: #ff87b2;
$mainlightier: #ff87b2;
$mainThin: #FFB4CF;

$secondary: #020202;
$secondaryDark: #000000;
$secondaryLight: #474747;
$secondarylightier: #777777;

$complementaryA: #6181f3;
$complementaryADark: #385bd7;
$complementaryALight: #89a4ff;
$complementaryAlightier: #b6c6ff;

$complementaryB: #149849;
$complementaryBDark: #004b1e;
$complementaryBLight: #41ce7a;
$complementaryBlightier: #7ddda4;

$alert: #20d15d;
$alertDark: #20b954;

$warning: #e84558;
$warningDark: #d42d41;
$warningBkg : #FFEEF0;

$white: #ffffff;

$disabled: #9b9b9b;
$disabledText: #d9d9d9;

$bkgNav: #fafafa;
$inactiveNav: #f6f6f6;
$inactiveNavText: #cfcfcf;

$scrollBar: #4b4b4b;
$scrollBarBack: #b9c8e0;


// New

$black: #000;
$mainGray: #E6E6E6;
$secondaryGray: #BDBDBD;
$secondaryGrayDark: #EDEDED;
$purple: #340283;
$grayText: #8E8E8E;
$naval: #1A5B8B;



