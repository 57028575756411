@import '../../styles/colors.scss';

.wrapper-content {
  h1 {
    color: $black;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
