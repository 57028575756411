@import '../../../../styles/colors.scss';
.sidebar {
  background-color: $secondaryGrayDark;
  width: 266px;
  padding: 16px;
  position: fixed;
  height: 100vh;
  z-index: 15;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.2s ease-in;
  .top {
    height: 100%;
    background-color: $white;
    padding: 10px;
    border-radius: 25px;
    
    .logo {
      display: flex;
      align-items: center;
      margin: 10px 0;
      margin-bottom: 50px;
      padding: 0px 14px;
      span {
        cursor: pointer;
        margin-right: 16px;
      }
      img {
        opacity: 1;
        position: relative;
        transition: all 0.1s ease-in-out;
        width: 100px;
      }
    }
  }
  &.closed {
    width: 70px;
    .top {
      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        span {
          margin-right: 0;
        }
        img {
          opacity: 0;
          position: absolute;
          z-index: -1;
        }
      }
    }
  }
}
