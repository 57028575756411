.main___button {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    color: white;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
    outline: none;
    border: none;
    &:hover {
        opacity: 0.8;
        transition-duration: 0.5s;
    }
}