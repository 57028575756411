@import '../../../../../styles/colors.scss';

.sidebar-item {
  color: $black;
  padding: 16px 0;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  &.active {
    font-weight: 700;
  }
  &.disabled {
    label {
      cursor: not-allowed;
      color: $secondarylightier;
    }
    cursor: not-allowed;
  }
  &.closed {
    display:flex ;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    label {
      opacity: 0;
      cursor: none;
      position: absolute;
      z-index:-1
    }
    .active-section{
      display: none;
    }
  }

  label {
    opacity: 1;
    position: block;
    cursor: pointer;
    margin-left: 16px;
    font-size: 16px;
    line-height: 16px;
    transition: all 0.1s ease-in-out;
  }
  img {
    width: 30px;
  }
  .active-section{
    position: absolute;
    right: -17px;
    top: 24px;
  }
}
