@import '../../../styles/colors.scss';
.MuiFormLabel-root {
    color: $black!important;
    font-weight: 700!important;
    font-family: 'Montserrat', sans-serif!important;
}

.MuiInputBase-root {
    font-family: 'Montserrat', sans-serif!important;
    &::before {
        border-bottom: 2px solid $secondaryGray!important;
    }
    &::after {
        border-bottom: 2px solid $black!important;
    }
}

.recovery {
    margin-top: 50px;
    .message {
        color: $black;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        display: block;
        
    }
}