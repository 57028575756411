@import '../../styles/colors.scss';
.card-info {
    padding: 6px 0px;
    h1 {
        font-size: 14px!important;
        font-weight: 700!important;
        color: $black!important;
    }
    p {
        margin: 0;
        margin-bottom: 3px;
        font-size: 12px;
        font-weight: 400;
        color: $black;
    }
}