@import '../../../../styles/colors.scss';

.footer_auth {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $mainGray;
    padding: 7px 12px;
    position: absolute;
    width: 100%;
    bottom: 0;

    p {
        color: $black;
        font-size: 12px;
    }
    a {
        color: $black;
        font-size: 12px;
        outline: none;
        text-decoration: none; 
    }

    .two {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        img {
            width: 25px;
        }
    }
}