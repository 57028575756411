@import '../../styles/colors.scss';
.MuiAccordion-root {
    border: 1px solid $mainGray;
    box-shadow: none!important;
    border-radius: 20px!important;
    .Mui-expanded {
        margin-bottom:0px!important;
    }
}

.header-collapse {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    align-items: center;
    img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
    }

    h2 {
        font-size: 14px;
        font-weight: 600;
    }
}

.options-collapse {
    p {
        font-size: 12px;
        font-weight: 600;
    }
    button {
        background-color: transparent;
        outline: none;
        border: none;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }
}

.MuiTabs-flexContainer {
    button {
        text-transform: capitalize;
        font-family: 'Montserrat', sans-serif;
    }

    .MuiButtonBase-root.Mui-selected {
        color: $naval;
        font-weight: 600;
    }
}

.MuiTabs-indicator {
    background-color: $naval!important;
}

