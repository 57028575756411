@import '../../../../styles/colors.scss';
.header_auth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 200px;
    }
    p {
        color: $black;
        font-size: 18px;
    }
}