@import '../../styles/colors.scss';
.wrapped-content {
    h1 {
        color: $black;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
    }
    hr {
        border: 1px solid $secondaryGrayDark;
    }
}